<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code flex items-center justify-center" />
    <div class="column container">
      <div class="row">
        <q-card
          square
          class="bg-white"
        >
          <q-card-section :class="`bg-primary text-white text-uppercase`">
            {{ $t('where_would_you_like_to_stay') }}
          </q-card-section>
          <q-separator />
          <q-card-section class="column">
            <div class="row q-col-gutter-sm">
              <div class="col-12 col-sm-6">
                <m-location-search
                  ref="pickUp"
                  :value="stash.parameters.location"
                  :label="$t('input.city_name')"
                  no-shadow
                  keep-open
                  @selected="locationSelect"
                />
              </div>
              <div class="col-12 col-sm-6">
                <q-select
                  v-model="stash.parameters.adults"
                  :options="options()"
                  stack-label
                  :label="$t('number_of.adults')"
                  emit-value
                />
              </div>
              <div class="col-6">
                <date-field
                  v-model="stash.parameters.check_in"
                  :min="minCheckIn"
                  :label="$t('check_in')"
                />
              </div>
              <div class="col-6">
                <date-field
                  v-model="stash.parameters.check_out"
                  :min="minCheckout"
                  :max="maxCheckout"
                  :label="$t('check_out')"
                />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="row">
        <div class="col">
          <m-unified-users v-model="people" />
        </div>
      </div>
      <div class="cta row reverse">
        <q-btn
          :disabled="$v.$invalid"
          size="lg"
          color="primary"
          class="full-width"
          @click="submit"
        >
          {{ $tc('find.hotel', 2) }}
        </q-btn>
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import date from 'utils/date-time'
import { query } from 'api/places'
import { required } from 'vuelidate/lib/validators'
import model from './model'
import authentication from 'mixins/authentication'
import { MUnifiedUsers, MLocationSearch, dateField } from 'components/'
import { handleErrors } from 'utils/utils'

export default {
  name: 'OndemandHotel',
  components: {
    dateField,
    MUnifiedUsers,
    MLocationSearch
  },
  mixins: [ authentication ],
  data () {
    return {
      minCheckIn: date.newDate(),
      ...model()
    }
  },
  computed: {
    people: {
      get () {
        return {
          traveller: this.stash.parameters.traveller,
          requester: this.stash.parameters.requester
        }
      },
      set (val) {
        this.stash.parameters.requester = val.requester
        this.stash.parameters.traveller = val.traveller
      }
    },
    minCheckout () {
      const d = date.newDate(this.stash.parameters.check_in)
      return date.addToDate(d, { days: 1 })
    },
    maxCheckout () {
      const d = date.newDate(this.stash.parameters.check_in)
      return date.addToDate(d, { days: 89 })
    }
  },
  watch: {
    'stash.parameters.check_in' (val) {
      if (val.toISODate() >= this.stash.parameters.check_out.toISODate()) {
        this.stash.parameters.check_out = this.minCheckout
      }
    },
    '$route.query.location': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.stash.parameters.location = val
        }
      }
    }
  },
  validations: {
    stash: {
      parameters: {
        location: { required },
        traveller: { required },
        requester: { required }
      }
    }
  },
  created () {
    this.$store.commit('ondemand/clear')
  },
  methods: {
    locationSelect (value) {
      this.stash.parameters.location = value
    },
    async autocomplete (terms) {
      try {
        const response = await query(terms)

        return response.data.map(location => {
          return {
            label: location.description,
            value: location.place_id,
            icon: 'place'
          }
        })
      } catch (err) {
        handleErrors(err)
      }
    },
    submit () {
      this.$store.dispatch('ondemand/stash', this.stash)
      this.$router.push({ name: 'ondemand-hotel-availability' })
    },
    options () {
      let options = []
      for (let i = 1; i < 11; i++) {
        options.push({ label: i.toString(), value: i })
      }
      return options
    }
  }
}
</script>

<style lang="stylus" scoped>
.mobile
  .code
    display none
  .column.container
    margin 32px
  .cta
    background-color #fafafa
    padding 16px 8px
    position fixed
    left 0
    right 0
    bottom 0
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 5px 8px rgba(0,0,0,0.14), 0 1px 14px rgba(0,0,0,0.12);
.cta
  padding 8px
.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(0, 0, 0, .2)
  overflow hidden
  background-image url('~assets/backgrounds/hotel.jpg')
  background-position: 0 70%;
  background-size: cover;
  text-overflow: clip;
.column.container
  margin-top -64px
.q-card
  width 80vw
  max-width 600px
</style>
