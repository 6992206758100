<template>
  <router-link :to="{ name: 'ondemand-hotel-details', params: { token: hotel.hotel_token }}">
    <q-card v-ripple class="cursor-pointer">
      <q-card-section class="row no-padding non-selectable">
        <m-image
          :src="picture"
          :fallback="require('assets/blank-hotel.png')"
          class="col-3 picture"
          style="background-color:#f1f1f1"
          height="90px"
        />
        <div class="col column q-px-md q-py-sm">
          <div class="row text-grey-7">
            <strong class="text-blue-grey-6 capitalize">{{ hotel.name }}</strong>
          </div>
          <q-rating
            slot="right"
            v-model="rating"
            :max="5"
            class="row"
            readonly
            size="16px"
          />
        </div>
        <div
          class="col-3 row justify-center items-center content-center text-center bg-white"
          v-html="$t('hotel.rooms_from_price_per_night', {price: hotel.min_price.text})"
        />
      </q-card-section>
    </q-card>
  </router-link>
</template>

<script type="text/javascript">
import mImage from 'components/MImage'
export default {
  name: 'HotelCard',
  components: {
    mImage
  },
  props: {
    'hotel': null
  },
  computed: {
    picture () {
      return this.hotel.pictures[Object.keys(this.hotel.pictures).find(a => this.hotel.pictures[a].length > 0)] || '404'
    },
    rating () {
      return !this.hotel.rating ? 0 : this.hotel.rating
    },
    address () {
      if (Array.isArray(this.hotel.address.address)) {
        let address = this.hotel.address.address.slice(-2)
        return `${address[0]}, ${address[1]}`
      }

      return this.hotel.address.city
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card
  max-height 120px
  position relative
  background-color #fff

.segment
  font-size 2em
  margin-top 8px
  .line
    position relative
    margin: 0 8px
    height: 1px
    background-color currentColor
    &:before,
    &:after
      content ""
      position absolute
      width 10px
      height 10px
      background currentColor
      border-radius 100%
      top -5px
    &:after
      right 0

.price
  font-size 1.5em
  margin 8px 0

.picture
  background-size 100%
  background-position center
</style>
