<template>
  <q-page>
    <div class="layout-padding">
      <div
        v-if="summary"
        class="row q-col-gutter-sm"
      >
        <div class="col-sm-12">
          <q-card class="bg-white">
            <q-card-section :class="`bg-primary`">
              {{ $t('booking.summary') }}
            </q-card-section>
            <q-card-section>
              <div
                class="row"
                style="margin-top: 16px"
              >
                <div class="col-8">
                  <div class="row">
                    <div class="col-12">
                      <sub>{{ $tc('content_type.hotel') }}</sub>
                      <h6 class="text-grey-9">
                        {{ summary.hotel.name }}
                      </h6>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <sub>{{ $t('hotel.room_type') }}</sub>
                      <h6 class="text-grey-9">
                        {{ summary.room.roomType }}
                      </h6>
                      {{ summary.booking.rate_description }}
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <m-image
                    :src="summary.hotel.pictures.photo_bar"
                    width="100%"
                    style="max-height: 130px"
                  />
                </div>
              </div>

              <div class="row" style="margin-top: 16px">
                <div class="col-6">
                  <sub>{{ $t('check_in') }}</sub>
                  <h6 class="text-grey-9">
                    {{ summary.booking.check_in.date | date }}
                  </h6>
                </div>
                <div class="col-6">
                  <sub>{{ $t('check_out') }}</sub>
                  <h6 class="text-grey-9">
                    {{ summary.booking.check_out.date | date }}
                  </h6>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <sub>{{ $t('cancellation_policy') }}</sub>
                  <h6 class="text-grey-9">
                    {{ summary.room.cancellation_policy }}
                  </h6>
                </div>
              </div>

              <q-separator class="hr" />
              <div v-if="mealAllowance" class="row">
                <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('booking.meal_allowance') }}</span>
                <span
                  class="col-2 text-grey-9 text-right"
                > {{ formatMoney(mealAllowance) }}</span>
              </div>
              <div v-if="summary.booking.mobilleo_booking_fee" class="row">
                <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('booking.fee') }}</span>
                <span
                  class="col-2 text-grey-9 text-right"
                >{{ summary.booking.mobilleo_booking_fee.text }}</span>
              </div>
              <div v-if="summary.booking.booking_total" class="row">
                <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('net_amount') }}</span>
                <span class="col-2 text-grey-9 text-right">{{ summary.booking.booking_total.text }}</span>
              </div>
              <div v-if="summary.booking.vat" class="row">
                <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('tax.vat') }}</span>
                <span class="col-2 text-grey-9 text-right">{{ summary.booking.vat.text }}</span>
              </div>
              <q-separator class="hr" />
              <h6
                v-if="summary.booking.total_cost"
                class="row text-bold"
              >
                <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('total_cost') }}</span>
                <span class="col-2 text-grey-9 text-right">{{ summary.booking.total_cost.text }}</span>
              </h6>
            </q-card-section>
          </q-card>
        </div>
        <div class="col-sm-12">
          <m-attach-to-booking-request
            :suggested="summary.suggested_journey_name"
            :username="stash.parameters.traveller.value"
            :journey="journey"
            :show-travel-type="orgGetter && orgGetter.attributes.travel_policy.hotel.enabled"
            @change="(val) => $store.dispatch('ondemand/journey', val)"
            @reasonChange="(val) => reason_for = val"
            @labelChange="(val) => labels = val"
            @travelTypeChange="(val) => travelType = val"
          />
        </div>

        <payment-buttons
          :total-amount="summary.booking.total_cost.amount"
          :requester="stash.parameters.requester"
          :journey="journey"
          :reason-for="reason_for"
          :labels="labels"
          :booking-token="booking_token"
          :travel-type="(orgGetter && orgGetter.attributes.travel_policy.hotel.enabled) || !orgGetter ? travelType : 'Private'"
        />

        <sub
          class="col-12 text-center text-faded"
          style="margin-top: 8px"
          v-html="$t('mobilleo_privacy_and_terms', { privacyURL: partner.privacyURL, termsURL: partner.termsURL })"
        />
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import authentication from 'mixins/authentication'
import { summary } from 'api/hotel'
import date from 'utils/date-time'
import loading from 'utils/loading'
import { mapGetters } from 'vuex'
import { MAttachToBookingRequest, MImage } from 'components/'
import paymentButtons from 'pages/payments/payment-method-buttons'
import { Notify } from 'quasar'
import router from 'router'
import store from 'store'
import i18n from 'i18n'
import { travelPolicy } from 'api/organisations'
import Dinero from 'dinero.js'
import { handleErrors } from 'utils/utils'

export default {
  name: 'OndemandHotelSummary',
  components: {
    paymentButtons,
    MAttachToBookingRequest,
    MImage
  },
  filters: {
    date: value => date.toHugeDate(value)
  },
  mixins: [authentication],
  data () {
    return {
      summary: null,
      reason_for: null,
      labels: null,
      travelType: null,
      mealAllowance: null
    }
  },
  computed: {
    ...mapGetters({
      orgGetter: 'organisation',
      stash: 'ondemand/stash',
      journey: 'ondemand/journey',
      partner: 'partner'
    }),
    booking_token () {
      return this.summary.booking.links.booking.split('/').pop()
    }
  },
  async beforeMount () {
    if (this.orgGetter) {
      const policy = await travelPolicy(this.orgGetter.slug)
      this.mealAllowance = Number(policy.data[0].hotel.meal_allowance)
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters['partner']

    loading.start({
      message: i18n.t('loading.checking.room_availability'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const response = await summary(to.params.token)

      next((vm) => {
        vm.summary = response.data
        loading.stop()
      })
    } catch (err) {
      loading.stop()
      if (err.data.title === 'HotelzonBookWithConfermaException') {
        Notify.create({
          message: i18n.t('error.hotel.no_online_payments'),
          enter: 'bounceInUp',
          leave: 'bounceOutDown',
          position: 'bottom',
          appear: true
        })
        router.push({ name: 'ondemand-hotel-availability' })
      } else {
        handleErrors(err)
        router.push({ name: 'ondemand-hotel-details' })
      }
      next(false)
    }
  },
  methods: {
    formatMoney (amount) {
      if (amount) {
        return Dinero({ amount, currency: 'GBP' }).toFormat('$0.00')
      }
      return false
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 768px
span, .hr
  margin-top 16px
</style>
